<template>
  <div class="flex gap-2.5" :class="{ 'flex-col gap-4': inFooter }">
    <NuxtLink
      v-if="!hideIos"
      to="https://apps.apple.com/gb/app/hub-of-hope/id1337090866"
      target="_blank"
      class="hover:scale-105 transition-transform duration-200"
    >
      <img
        src="~/assets/images/appstore.svg"
        :width="buttonsWidth"
        :height="buttonsHeight"
        alt="Download on the App Store"
      />
    </NuxtLink>
    <NuxtLink
      v-if="!hideAndroid"
      to="https://play.google.com/store/apps/details?id=com.chasingthestigma.hubofhope"
      target="_blank"
      class="hover:scale-105 transition-transform duration-200"
    >
      <img
        v-if="inFooter"
        src="~/assets/images/googleplay-white.svg"
        :width="buttonsWidth"
        :height="buttonsHeight"
        alt="Get it on Google Play"
      />
      <img
        v-else
        src="~/assets/images/googleplay.svg"
        :width="buttonsWidth"
        :height="buttonsHeight"
        alt="Get it on Google Play"
      />
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import '~/assets/images/appstore.svg'
import '~/assets/images/googleplay.svg'
import '~/assets/images/googleplay-white.svg'
const props = defineProps({
  inFooter: {
    type: Boolean,
    default: false
  },
  hideAndroid: Boolean,
  hideIos: Boolean
})
const buttonsWidth = props.inFooter ? '135px' : '183px'
const buttonsHeight = props.inFooter ? '40px' : '50px'
</script>
